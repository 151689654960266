.method-main-container {
  display: grid;
  background-color: hsl(240, 15%, 15%);
  row-gap: 4rem;
  padding-bottom: 5rem;
}

.method-our-method-container {
  position: relative;
  display: grid;
  min-height: 48rem;
}

.method-our-method-content-container {
  position: absolute;
  display: grid;
  z-index: 1;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}
.method-our-method-content-left-container {
  display: grid;
  align-items: center;
  align-content: center;
  height: initial;
  background-color: hsla(30, 50%, 20%, 0.55);
  row-gap: 1rem;
  padding: 5rem;
}
.method-our-method-img {
  z-index: 1;
  width: 100%;
  height: 48rem;
  object-fit: cover;
}

.method-our-method-title {
  font-size: 3.5rem;
  text-shadow: 2px 2px 2px black;
}

.method-our-method-description {
  font-size: 1.3rem;
  text-shadow: 2px 2px 2px black;
}

/* ###### What you get ###### */
.method-what-you-get-container {
  display: grid;
  justify-items: center;
  row-gap: 3rem;
  padding: 0 5rem;
}

.method-what-you-get-title-container {
  display: grid;
  justify-items: center;
  row-gap: 1rem;
}

.method-what-you-get-title {
  font-size: 2.5rem;
}

.method-what-you-get-title-underline {
  height: 3px;
  background-color: #ff8000;
  width: 4.5rem;
  border-radius: 16rem;
}

.method-check-mark {
  color: hsl(30, 100%, 50%);
}

.method-what-you-get-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  column-gap: 3rem;
  padding-left: 4rem;
}

.method-what-you-get-item {
  display: flex;
  grid-template-columns: max-content auto;
  column-gap: 1rem;
}

.method-what-you-get-item p {
  line-height: 1.75rem;
  font-size: 1.15rem;
}

/* @media screen and (max-width: 600px) {
  .method-what-you-get-item-container {
    grid-template-columns: 1fr;
  }
} */
