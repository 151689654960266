.contact-form-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: hsl(240, 14%, 18%);
  padding: 5rem;
  min-height: 48rem;
}

.contact-form-info-container {
  padding: 3rem 14rem;
}

.contact-form-info-title-text {
  font-size: 1.5rem;
  color: #ff8000;
}

.contact-form-info-description-text {
  font-size: 2.5rem;
  padding: 1rem 0 2rem 0;
}

.contact-form-info-email-title {
  font-size: 1.15rem;
  line-height: 2rem;
}

.contact-form-info-email-text {
  font-size: 1.15rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.contact-form-block-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  padding: 0 3.5rem;
}

.contact-form-block-name-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.5rem;
  row-gap: 0.5rem;
}

.contact-form-block-input-title {
  font-size: 1.25rem;
}

.contact-form-block-input {
  min-height: 3rem;
  padding: 0.1rem 1rem;
  font-size: 1rem;
}

.contact-form-block-input:focus {
  outline: none !important;
  border-radius: 0.25rem;
  border: 3px solid #ff8000;
  box-shadow: 0 0 4px #ec9e50;
}

.contact-form-block-email-container {
  display: grid;
  row-gap: 0.5rem;
}

.contact-form-block-message-container {
  display: grid;
  row-gap: 0.5rem;
}

.contact-form-block-message-text-area {
  height: 8rem;
  font-size: 1rem;
  padding: 1rem;
  resize: none;
}

.contact-form-block-message-text-area:focus {
  outline: none !important;
  border-radius: 0.25rem;
  border: 3px solid #ff8000;
  box-shadow: 0 0 4px #ec9e50;
}

.contact-send-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.65rem;
  width: fit-content;
  align-items: center;
  background-color: #000;
  border: 2px solid #ff8000;
  border-radius: 0.25rem;
  padding: 0.95rem 0.2rem 0.95rem 1.5rem;
}

.contact-send-button-container:hover {
  cursor: pointer;
  filter: brightness(1.15);
}

.contact-send-button-text {
  font-size: 1.085rem;
  font-weight: 600;
}
.contact-send-button-img {
  width: 1.45rem;
}

/* @media screen and (max-width: 480px) {
  html {
    font-size: 0.3em;
  }

  .aos-animate {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 0.3em;
  }
  
  .contact-form-main-container {
    grid-template-columns: 1fr;
    padding: 4rem 2.5rem;
  }

  .contact-form-info-container {
    padding: 0 11rem 4rem;
  }
} */
