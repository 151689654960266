nav {
  position: fixed;
  width: 100%;
  min-height: 4.2rem;
  top: 0;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 8fr;
  justify-items: space-between;
  user-select: none;
  background-color: hsla(240, 14%, 14%, 1);
  z-index: 1000;
  font-style: normal;
}

.nav-company-name-container {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}

.nav-company-logo {
  height: 2rem;
  margin-left: 1.2rem;
}

.nav-company-title {
  font-size: 1.28rem;
  text-align: left;
  margin: 0 0.8rem;
  color: #ff8000;
}

.nav-list {
  list-style-type: none;
  display: flex;
  justify-content: right;
  align-items: center;
  position: relative;
  text-decoration: none;
}

.nav-items {
  padding: 0 1rem;
  height: 100%;
  text-decoration: none;
}

.nav-link {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: hsl(30, 100%, 50%);
}

.nav-link-p {
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  justify-content: center;
  text-align: center;
}

.nav-link-p:hover {
  color: white;
}

.nav-toggle-btn {
  display: none;
  position: absolute;
  right: 0.6rem;
  top: 0.9rem;
  padding: 0.3rem;
  filter: invert(1);
  width: 1.8rem;
  z-index: 1000;
}

.nav-toggle-btn:hover {
  filter: invert(0.8);
}

.nav-outlet {
  height: fit-content;
  margin-top: 4.2rem;
  position: relative;
}

@media screen and (max-width: 500px) {
  .nav-list {
    flex-direction: column;
  }

  .nav-items:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    margin-top: 4rem;
  }

  .nav-items {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    height: fit-content;
    padding: 1.1rem 0;
  }

  .nav-toggle-btn {
    display: block;
  }
}
