.about-main-container {
  display: grid;
  background-color: hsl(240, 14%, 18%);
  padding: 4rem;
  user-select: none;
  row-gap: 5rem;
  justify-items: center;
}
.about-title-text {
  font-size: 2.5rem;
}

.about-item-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5rem;
  row-gap: 5rem;
  color: black;
}

.about-item {
  display: grid;
  grid-template-rows: auto 18rem;
  background-color: white;
  overflow: hidden;
  border-radius: 0.8rem;
  box-shadow: 3px 3px 4px rgb(39, 39, 39);
}

.about-item-image {
  width: 100%;
  height: 10.5rem;
  object-fit: cover;
}

.about-item-content-container {
  display: grid;
  padding: 3rem;
  row-gap: 2rem;
  align-content: flex-start;
  background-color: #cdcdcd;
  overflow: hidden;
}

.about-item-title {
  width: 100%;
  height: fit-content;
  color: #4a0d66;
  text-align: center;
  font-size: 2.25rem;
  font-weight: 700;
}

.about-item-body {
  font-size: 1.25rem;
  text-align: justify;
  line-height: 1.5rem;
}

.about-board-of-directors-container {
  width: 50%;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: 1fr;
  padding: 0 6rem 2rem;
  user-select: none;
  column-gap: 9.5rem;
}

.about-person-item {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-color: hsl(240, 14%, 30%);
  border-radius: 0.5rem;
  row-gap: 0.8rem;
}

.about-person-item:hover img {
  filter: none;
  transition: ease 0.65s;
}

.about-person-item:hover {
  background-color: hsl(240, 14%, 35%);
  transition: ease 0.3s;
}

.about-img-item {
  display: flex;
  justify-content: stretch;
  border-radius: 50%;
  overflow: hidden;
  width: 10rem;
  height: 10rem;
  margin: 0 auto 0.2rem auto;
}

.about-director-img {
  width: 10.6rem;
  height: 10.6rem;
  filter: grayscale(1);
}

.about-person-item-name {
  font-size: 1.2rem;
  text-align: center;
  margin: 1rem 0 0.5rem 0;
}

.about-person-item-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-orientation: sideways-right;
}

.about-person-item-mesage {
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin: 0.8rem 0.8rem 0 0.8rem;
  text-align: justify;
  user-select: none;
}

/* @media screen and (max-width: 600px) {
  .about-board-of-directors-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    row-gap: 6rem;
  }

  .about-who-we-are-description {
    line-height: 3rem;
    padding: 0 1.5rem;
  }

  .about-main-container {
    overflow: hidden;
  }

  .about-item-main-container {
    grid-template-columns: 1fr;
  }
} */
