/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 1rem;
}

p {
  margin: 0px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: hsl(240, 14%, 14%);
  width: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: white;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}


/* Small Mobiles */
/* @media screen and (max-width: 480px) {
  .aos-animate {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }
} */

/* Mobiles */
/* @media only screen and (min-width: 600px) {
  html {
    font-size: 0.3em;
  }

  .aos-animate {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }
} */

/* Laptops */
/* @media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
} */

/* Large Laptops */
/* @media only screen and (min-width: 992px) {
  html {
    font-size: 0.6em;
  }
} */

/* HD Screens */
/* @media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
} */

/* Full HD Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
} */

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
} */
