.service-main-container {
  padding-top: 4rem;
  background-color: hsl(240, 14%, 18%);
  user-select: none;
}

.service-info-container {
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 2rem;
  margin-top: 1rem;
}

.service-title-text {
  font-size: 2.5rem;
}

.service-description-text {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.service-items-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4rem 4rem;
  column-gap: 4rem;
  row-gap: 4rem;
}

.service-item {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 4rem;
  border-radius: 1rem;
  background-color: hsl(240, 14%, 30%);
  box-shadow: 4px 8px 12px hsl(240, 14%, 15%);
}

.service-item:hover {
  transition: ease 0.8s;
  background-color: hsl(240, 14%, 35%);
  box-shadow: 4px 8px 12px hsl(240, 14%, 19%);
}

/* .service-item-1 {
}
.service-item-2 {
  background-color: hsl(240, 14%, 30%);
} */

.service-item-title {
  font-size: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
}

.service-item-description {
  font-size: 1.15rem;
  text-align: justify;
  line-height: 1.65rem;
}

.service-round-div {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  background: rgb(128, 123, 255);
  border: 0.3rem solid white;
  margin: 2rem auto;
  overflow: hidden;
}

.service-circle-img:hover {
  transition: ease 1s;
  transform: scale(1.1);
  user-select: none;
}

.service-circle-img {
  width: 9.5rem;
  height: 9.5rem;
  margin: auto;
}

/* @media only screen and (max-width: 600px) {
  .service-items-container {
    grid-template-columns: 1fr;
    padding: 4.5rem;
    row-gap: 4.5rem;
  }
  .service-description-text {
    padding: 0 4rem;
  }
}

@media only screen and (min-width: 600px) {
  .service-items-container {
    grid-template-columns: 1fr;
    padding: 2rem 4.5rem 4.5rem 4.5rem;
    row-gap: 4.5rem;
  }
  .service-description-text {
    padding: 0 4rem;
  }
}

@media only screen and (min-width: 992px) {
  .service-items-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4rem 3rem;
    row-gap: 4rem;
    column-gap: 4rem;
  }

  .service-item {
    padding: 3rem;
    border-radius: 0.65rem;
  }

  .service-description-text {
    padding: 0rem 4rem;
  }
}

@media only screen and (min-width: 1920px) {
  .service-items-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4.5rem 4.5rem;
    row-gap: 4.5rem;
    column-gap: 4.5rem;
  }
  .service-item {
    padding: 4rem;
    border-radius: 1rem;
  }
} */
