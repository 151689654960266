.home-main-container {
  font-size: 1em;
}

.home-hero-main-container {
  position: relative;
  user-select: none;
}

.christmas-container {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0.8em;
}

.christmas-text {
  color: var(--background);
  font-size: 2.25em;
  font-weight: bold;
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
  text-shadow: -6px 6px 15px rgba(0, 0, 0, 0.5),
    6px -6px 15px rgba(255, 255, 255, 0.8);
}

.christmas-text:hover {
  animation: shake 0.8s;
  cursor: pointer;
  animation-iteration-count: infinite;
}

.home-hero-image-container {
  display: grid;
  width: 100%;

}

.home-hero-image {
  z-index: -1;
  width: 100%;
  /* height: calc(100vh - 4.2rem); */
  object-fit: cover;
  background-color: hsl(240, 15%, 15%);
  min-height: calc(100vh - 4em);
}

.home-hero-title-container {
  position: absolute;
  display: grid;
  row-gap: 1em;
  justify-content: space-around;
  width: 35%;
  align-content: center;
  padding-left: 5em;
  height: 100%;
}

.home-hero-title-text-1 {
  width: 100%;
  font-size: 1.5em;
  color: #ff8000;
}

.home-hero-title-text-2 {
  font-size: 2.8em;
  font-weight: bold;
}

.home-hero-title-text-3 {
  font-size: 1.5em;
}

.home-hero-chevron {
  position: absolute;
  width: 2.7em;
  bottom: 0;
  left: 50%;
  animation: updownshake 1s;
  cursor: pointer;
  animation-iteration-count: infinite;
}

.home-content-container {
  display: grid;
  height: 18em;
  align-items: center;
  align-content: center;
  background-color: hsl(240, 14%, 18%);
}
.home-content-about-short {
  display: grid;
  justify-items: center;
  row-gap: 2em;
  padding-bottom: 2em;
}
.home-content-about-title-text {
  font-size: 2.6em;
}
.home-content-about-message {
  font-size: 1.45em;
  padding: 0 3em;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes updownshake {
  0% {
    transform: translate(0, 1px);
  }
  10% {
    transform: translate(0, 2px);
  }
  20% {
    transform: translate(0, 3px);
  }
  30% {
    transform: translate(0, 2px);
  }
  40% {
    transform: translate(0, 1px);
  }
  50% {
    transform: translate(0, 0);
  }
  60% {
    transform: translate(0, -1px);
  }
  70% {
    transform: translate(0, -2px);
  }
  80% {
    transform: translate(0, -3px);
  }
  90% {
    transform: translate(0, -2px);
  }
  100% {
    transform: translate(0, -1px);
  }
}

@keyframes arrows {
  0% {
    border-left: 3em/3 solid rgba(0, 0, 0, 0);
    border-bottom: 3em/3 solid rgba(0, 0, 0, 0);
    transform: translate(3em/-6, 3em * 4/-6) rotate(-45deg);
  }
  10%,
  90% {
    border-left: 3em/3 solid rgba(0, 0, 0, 0);
    border-bottom: 3em/3 solid rgba(0, 0, 0, 0);
  }
  50% {
    border-left: 3em/3 solid rgba(0, 0, 0, 0.5);
    border-bottom: 3em/3 solid rgba(0, 0, 0, 0.5);
    transform: translate(3em/-6, 0px) rotate(-45deg);
  }
  100% {
    border-left: 3em/3 solid rgba(0, 0, 0, 0);
    border-bottom: 3em/3 solid rgba(0, 0, 0, 0);
    transform: translate(3em/-6, 3em * 4/6) rotate(-45deg);
  }
}

/* Small Mobiles */
@media screen and (max-width: 480px) {
  .home-main-container {
    font-size: 0.32em;
  }

  .aos-animate {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }

  .home-hero-image {
    height: fit-content;
    min-height: 46em;
  }
}

@media screen and (min-width: 480px) {
  .home-main-container {
    font-size: 0.35em;
  }

  .aos-animate {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }

  .home-hero-image {
    height: fit-content;
    min-height: 48em;
  }
}

/* Mobiles */
@media only screen and (min-width: 600px) {
  .home-main-container {
    font-size: 0.4em;
  }

  .aos-animate {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
  }

  .home-hero-image {
    height: fit-content;
    min-height: 50em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  .home-main-container {
    font-size: 0.5em;
  }

  .home-hero-image {
    min-height: calc(100vh - 6.6em);
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  .home-main-container {
    font-size: 0.6em;
  }
  .home-hero-image {
    min-height: calc(100vh - 6em);
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  .home-main-container {
    font-size: 0.8em;
  }
  .home-hero-image {
    min-height: calc(100vh - 5.2em);
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  .home-main-container {
    font-size: 1em;
  }
  .home-hero-image {
    min-height: calc(100vh - 3.6em);
  }
}

/* 4K (Ultra HD) Screens */
@media only screen and (min-width: 3840px) {
  .home-main-container {
    font-size: 1.2em;
  }
}
