.footer-main-container {
  background-color: rgb(30, 30, 40);
}

.footer-company-title-container {
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content auto;
  padding-top: 1.5rem;
  column-gap: 0.35rem;
  align-items: center;
}

.footer-company-logo {
  height: 1.8rem;
  margin-left: 1.2rem;
}

.footer-company-title-1 {
  font-size: 1.15rem;
  color: #ff8000;
  margin-left: 0.5rem;
}

.footer-company-title-2 {
  font-size: 1.15rem;
  color: #ff8000;
}

.footer-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 18rem;
}

.footer-contact-info-container {
  display: grid;
  justify-content: center;
  align-content: center;
}

.footer-contact-item-container {
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 0.65rem;
  height: fit-content;
  margin: 0.7rem 0;
  align-items: center;
}

.footer-contact-item-container a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}

.footer-contact-icon {
  width: 1.4rem;
}

.footer-social-media-container {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  align-content: center;
  justify-content: center;
  column-gap: 0.5rem;
  user-select: none;
}
.footer-social-media-icon {
  width: 1.5rem;

  cursor: pointer;
}

.footer-email-container {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-content: center;
  justify-content: center;
  column-gap: 0.5rem;
  align-items: center;
}

.footer-email-container a {
  /*  */
  color: #fff;
  text-decoration: none;
}

.footer-copyright-text {
  font-size: 0.85rem;
  user-select: none;
  text-align: center;
  text-shadow: 1px 1px 1px #000;
  padding-bottom: 1.5rem;
}

/* @media screen and (max-width: 500px) {
  .footer-content-container {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    height: fit-content;
  }

  .footer-copyright-text {
    margin-top: 1.5rem;
  }

  .footer-social-media-container {
    order: 2;
  }
  .footer-contact-info-container {
    order: 3;
  }
  .footer-email-container {
    order: 1;
  }
} */
