.careers-main-container {
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: hsl(240, 14%, 20%);
  row-gap: 6rem;
  padding-bottom: 5rem;
}

.careers-dream-job-container {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  justify-items: center;
  background-color: hsl(240, 14%, 25%);
  padding: 5rem 1rem;
  min-height: 48rem;
}

.careers-dream-job-left-container {
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 4rem;
}

.careers-dream-job-title-container {
  display: grid;
  grid-template-columns: max-content max-content max-content;
}
.careers-dream-job-title {
  font-family: "Ambit", "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 3.5rem;
}

.careers-dream-job-title-red {
  color: hsl(28, 100%, 50%);
  font-weight: 600;
  text-shadow: 2px 2px 3px hsl(22, 90%, 40%);
}

.careers-dream-job-description {
  font-size: 1.35rem;
}

.careers-dream-job-button-container {
  display: grid;
  width: fit-content;
  align-items: center;
  background-color: #000;
  border: 2px solid #ff8000;
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  box-shadow: 0.1rem 0.1rem 1rem black;
}

.careers-dream-job-button-container:hover {
  cursor: pointer;
  filter: brightness(1.15);
}

.careers-dream-job-button-text {
  font-size: 1.085rem;
  font-weight: 600;
}

.careers-dream-job-right-container {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.careers-dream-job-img {
  width: 70%;
  border: 0.5rem solid #bcd;
  border-radius: 0.2rem;
  object-fit: cover;
  box-shadow: 4px 6px 16px black;
}

.careers-how-we-work-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;
  padding: 0 2rem;
}

.careers-how-we-work-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4rem;
  column-gap: 4rem;
}

.careers-how-we-work-title {
  font-size: 3rem;
  height: fit-content;
  text-align: center;
}

.careers-how-we-work-item {
  width: 100%;
}
.careers-how-we-work-description {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  color: black;
  background-color: #bcd;
  padding: 4.2rem 2rem;
  height: 100%;
  border-radius: 0.25rem;
  text-align: center;
  font-style: italic;
  box-shadow: 0.25rem 0.25rem 1rem black;
}

.careers-need-more-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
}

.careers-need-more-info-title {
  font-size: 1.75rem;
}

.careers-need-more-info-email {
  font-size: 1.5rem;
  text-decoration: none;
  color: #ff8000;
}

/* @media screen and (max-width: 500px) {
  .careers-dream-job-container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .careers-how-we-work-item-container {
    grid-template-columns: 1fr;
  }
} */
